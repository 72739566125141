/**
 *
 * Reference
 * ---------
 * https://docs.dropzone.dev/configuration/tutorials/combine-form-data-with-files#show-me-the-codes
 *
 * **/

import {appendSpinner} from "../utils";
import {csrfToken} from "./axios";

const DJANGO_FILE_FIELD_NAME = 'upload'


export function renderDropzone($elem) {
    Promise.all([
        import('dropzone/dist/dropzone.css'),
        import('dropzone'),
    ]).then(([_, module]) => {
        const submitButton = $elem.querySelector('[type="submit"]')
        new module.Dropzone($elem, {
            paramName: (n) => DJANGO_FILE_FIELD_NAME, // make dropzone compliant with Django
            url: $elem.action || window.location.href,
            uploadMultiple: true,
            autoProcessQueue: false,
            addRemoveLinks: true,
            parallelUploads: 100,
            maxFiles: 100,
            headers: {
                'X-CSRFTOKEN': csrfToken
            },
            init: function () {
                $elem.addEventListener('submit', (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    let queuedFiles = this.getQueuedFiles();
                    if (queuedFiles.length > 0) {
                        appendSpinner(submitButton)
                        this.processQueue()
                    }
                })
            },
            successmultiple: function (files, response) {
                window.location.reload()
            },
            completemultiple: function () {
                $elem.classList.remove('busy')
            },
            // https://docs.dropzone.dev/misc/tips
            previewsContainer: '.previews',
        });
    })
}

